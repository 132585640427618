export const LOCAL_SERVING_MODULE_STRINGS = {
    APPLYMODEL_POPUP: 'Applying model, please wait...',
    INITIALIZING:'Initialising environment...',
    INITIALIZING_POPUP: 'Initialising environment.',
    DOWNLOAD: 'Local environment not ready. Please download the model:',
    DOWNLOAD_POPUP: 'Downloading environment.',
    NEED_DATA: 'No results available. Waiting for data.',
    WEBASSEMBLY_DELETE_ERROR: 'Download error: Error deleting old WebAssembly files.',
    WEBASSEMBLY_SAVE_ERROR: 'Download error: Error saving WebAssembly files.',
    ONNX_SAVE_ERROR: 'Download error: Error saving ONNX model.',
    INITIALIZATION_ERROR_WEBASSEMBLY: 'Initialisation error: WebAssembly artefacts issue.',
    INITIALIZATION_ERROR_ONNX: 'Initialisation error: ONNX-Runtime session error.',
    INFERENCE_ERROR: 'Model inference error. Consider updating calibration data.'
};

export const SERVING_MODULE_STRINGS = {
    NEED_DATA: 'No results available. Waiting for data.',
    REQUEST_ERROR: 'Request error: ',
    CONNECTION_ERROR: 'Connection error: ',
    APPLYMODEL_POPUP: 'Applying model, please wait...'
};

export const SCANCORDER_MODULE_STRINGS = {
    NO_CALIBRATION_DIALOG_HEADER: 'No calibration found.',
    NO_CALIBRATION_DIALOG_INSTRUCTION: 'Please calibrate device first!',
    NO_SENSOR_CONNECTION_DIALOG_HEADER: 'No sensor connected.',
    NO_SENSOR_CONNECTION_DIALOG_INSTRUCTION: 'Please connect a ScanCorder device first!'
};
