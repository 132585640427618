<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        ScanCorder
        <template v-if="isConnected">
          <ion-icon
            v-if="powerStatus.batteryLevel > 60"
            style="float: right"
            class="bi bi-battery-full"
          ></ion-icon>
          <ion-icon
            v-else-if="powerStatus.batteryLevel > 30"
            style="float: right"
            class="bi bi-battery-half"
          ></ion-icon>
          <ion-icon
            v-else
            style="float: right"
            class="bi bi-battery"
          ></ion-icon>
        </template>
      </ion-card-title>
      <ion-card-subtitle v-if="isConnected">
        {{ deviceInfo.name }}
        {{
          deviceInfo?.sensorHead?.name ? " - " + deviceInfo.sensorHead.name : ""
        }}
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-grid v-if="!isConnected">
        <ion-row>
          <ion-col>
            <submit-button
              @click="connectToDevice"
              label="Connect"
              :processing="connectionInProgress"
              processingLabel="Connecting..."
            />
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="isConnected">
        <template v-if="isExpertMode">
          <ion-row>
            <ion-col>Device Name</ion-col>
            <ion-col>{{ deviceInfo.name }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>FW / HW Version</ion-col>
            <ion-col
              >{{ deviceInfo.firmwareVersion }} /
              {{ deviceInfo.hardwareVersion }}</ion-col
            >
          </ion-row>
          <ion-row>
            <ion-col>
              Connected Sensor
              <ion-icon
                :icon="refreshCircleOutline"
                @click="readSensorHeadInformation"
              />
            </ion-col>
            <ion-col>
              <template v-if="deviceInfo.sensorHead">
                <data-tree :data="deviceInfo.sensorHead"> </data-tree>
              </template>
              <template v-else> No sensor connected </template>
            </ion-col>
          </ion-row>
        </template>

        <ion-row>
          <ion-col>
            <ion-button
              fill="clear"
              expand="block"
              :disabled="connectionInProgress"
              v-if="isConnected"
              @click="promptUserForDisconnect"
            >
              Disconnect
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" @click="startMeasurement">
              scan!
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
    <ion-loading
      :is-open="measurementInProgress"
      :message="(darkcurrentMode ||  calibrationMode) ? 'Calibrating sensor...' : 'Measuring sample...'"
      spinner="circles"
    />
    <ion-alert
      :is-open="alertForceCalibration"
      :header=SCANCORDER_MODULE_STRINGS.NO_CALIBRATION_DIALOG_HEADER
      :message=SCANCORDER_MODULE_STRINGS.NO_CALIBRATION_DIALOG_INSTRUCTION
      :buttons="[{ text: 'OK', handler: resetAlertCalibration }]"
    />
    <ion-alert
      :is-open="alertSensorConnection"
      :header=SCANCORDER_MODULE_STRINGS.NO_SENSOR_CONNECTION_DIALOG_HEADER
      :message=SCANCORDER_MODULE_STRINGS.NO_SENSOR_CONNECTION_DIALOG_INSTRUCTION
      :buttons="[{ text: 'OK', handler: resetAlertSensorConnection }]"
    />
    <ion-row v-if="isConnected">
      <ion-col>
        <ion-accordion-group>
          <ion-accordion value="1">
            <ion-item slot="header">
              <ion-label>Sensor Calibration</ion-label>
            </ion-item>
            <ion-grid slot="content">
              <ion-row>
                <ion-col>True Reflectance Value [%]</ion-col>
                <ion-col>
                  <ion-input
                    type="number"
                    min="1"
                    max="100"
                    step="1"
                    v-model="calibrationInfo.trueValuePercentage"
                    @ionBlur="checkTrueValueInput"
                    @ionInput="checkTrueValueInput"
                  />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>Calibration Data</ion-col>
                <ion-col>
                  <template v-if="displayCalibration">
                    <data-tree :data="displayCalibration"> </data-tree>
                  </template>
                  <template v-else-if="!calibrationMode">
                    No Calibration</template
                  >
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-button
                    fill="clear"
                    expand="block"
                    @click="clearCalibration"
                  >
                    Clear Calibration
                  </ion-button>
                </ion-col>
                <ion-col v-if="isFwAbove('2.0')">
                  <ion-button expand="block" @click="startDarkCurrent">
                    Record Dark Current
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button expand="block" @click="startCalibration">
                    Calibrate Sensor
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-accordion>
          <ion-accordion value="2" v-if="isFwAbove('2.0') && isExpertMode">
            <ion-item slot="header">
              <ion-label>Auto Exposure</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item
                v-for="(_value, key) in autoExposureParameters"
                :key="key"
              >
                <ion-label>{{ key }}</ion-label>
                <ion-input v-model="autoExposureParameters[key]"></ion-input>
              </ion-item>
              <ion-item>
                <submit-button
                  :processing="commandLineBusy"
                  size="default"
                  slot="end"
                  label="Start"
                  @click="startAutoExposure"
                />
              </ion-item>
            </ion-list>
          </ion-accordion>
          <ion-accordion value="3" v-if="isExpertMode">
            <ion-item slot="header">
              <ion-label>Measurement Parameters</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-label position="stacked">Exposure Time [ms]</ion-label>
                <ion-input
                  class="ion-text-right ion-padding"
                  type="number"
                  min="1"
                  max="1000"
                  v-model="exposureTime"
                ></ion-input>
                <submit-button
                  @click="setExposureTime"
                  slot="end"
                  size="default"
                  label="set"
                  :processing="commandLineBusy"
                  processingLabel="Setting..."
                ></submit-button>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Gain</ion-label>
                <ion-select
                  @ionChange="onGainSelectChange($event)"
                  v-if="isArrayParameter(sensorParameterOptions?.gains)"
                >
                  <ion-select-option
                    v-for="gainValue in sensorParameterOptions.gains"
                    :key="gainValue"
                    :value="gainValue"
                  >
                    {{ gainValue }}
                  </ion-select-option>
                </ion-select>
                <ion-input
                  v-else
                  type="number"
                  :min="sensorParameterOptions?.gain?.min || 1"
                  :max="sensorParameterOptions?.gain?.max || 10"
                  v-model="gain"
                ></ion-input>
                <submit-button
                  @click="setGain"
                  slot="end"
                  size="default"
                  label="set"
                  :processing="commandLineBusy"
                  processingLabel="Setting..."
                ></submit-button>
              </ion-item>
              <ion-item v-if="deviceInfo?.sensorHead?.driver === 0">
                <ion-label position="stacked">LED current [mA]</ion-label>
                <ion-input
                  type="number"
                  min="4"
                  max="150"
                  v-model="ledCurrent"
                ></ion-input>
                <submit-button
                  @click="setLedCurrent"
                  slot="end"
                  size="default"
                  label="set"
                  :processing="commandLineBusy"
                  processingLabel="Setting..."
                ></submit-button>
              </ion-item>
            </ion-list>
          </ion-accordion>
          <ion-accordion value="4" v-if="isFwAbove('2.0') && isExpertMode">
            <ion-item slot="header">
              <ion-label>Additional Settings</ion-label>
            </ion-item>
            <ion-grid slot="content">
              <ion-row
                v-for="(editableItem, index) in additionalInfoEditableItems"
                :key="'ei-' + index"
              >
                <ion-col size="3">
                  <ion-label position="stacked"> key </ion-label>
                  <ion-input fill="solid" v-model="editableItem.key" />
                </ion-col>
                <ion-col>
                  <ion-label position="stacked"> value </ion-label>
                  <ion-input fill="solid" v-model="editableItem.value" />
                </ion-col>
                <ion-col size="2" v-if="editableItem.key != ''">
                  <submit-button
                    @click="setAdditionalInfo(editableItem.key, 'null')"
                    expand="block"
                    label="clear"
                    :processing="commandLineBusy"
                  />
                </ion-col>
                <ion-col size="2">
                  <submit-button
                    @click="
                      setAdditionalInfo(editableItem.key, editableItem.value)
                    "
                    expand="block"
                    label="set"
                    :processing="commandLineBusy"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-accordion>
          <ion-accordion value="5" v-if="isFwAbove('2.0') && isExpertMode">
            <ion-item slot="header">
              <ion-label>Raw Command</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-label position="stacked"> Raw Command </ion-label>
                <ion-input fill="solid" v-model="rawCommandString" />
                <submit-button
                  @click="sendCommandString(rawCommandString)"
                  slot="end"
                  size="default"
                  label="set"
                  :processing="commandLineBusy"
                  processingLabel="Setting..."
                />
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>
  </ion-card>
</template>

<script setup>
import {
  IonButton,
  IonCardContent,
  IonCard,
  IonInput,
  IonLoading,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonSpinner,
  IonCardSubtitle,
  IonAccordion,
  IonAccordionGroup,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import { refreshCircleOutline } from "ionicons/icons";

import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { BleDevice, requestDevice } from "../utils/BleDevice";
import { events } from "@/utils/events";
import DataTree from "@/components/DataTree.vue";
import { useMetaStore } from "@/store/meta";
import { dataMessage } from "@/messages/dataMessage";
import { colorFromWavelength } from "@/utils/color";
import SubmitButton from "@/components/SubmitButton.vue";
import ScancorderSetting from "@/components/ScancorderSetting.vue";
import { SCANCORDER_MODULE_STRINGS } from '@/const/strings';

const BLE_UUIDS = {
  deviceService: {
    uuid: "a036d97c-3e15-4e6c-85af-9bd7627cd9b8",
    characteristics: {
      deviceName: "39ff4e8c-87f4-4c81-bcd7-d86a03338e1f",
      firmwareVersion: "5f031591-3ddd-4013-a859-fbd21f7bc077",
      hardwareVersion: "8b17065e-cac2-40b4-bd99-9e4ca6a59685",
    },
  },
  powerService: {
    uuid: "0000180f-0000-1000-8000-00805f9b34fb",
    characteristics: {
      batteryLevel: "00002a19-0000-1000-8000-00805f9b34fb",
      batteryVoltage: "daef0566-6dfa-433f-b697-92f4aabe4ef4",
      usbVoltage: "c8336f9b-412e-41ae-9ed9-2b88998bdbf9",
    },
  },
  sensorheadService: {
    uuid: "042a81c6-92e7-4bbd-b1aa-d06eaafcf337",
    characteristics: {
      connected: "3d343df0-c1fc-4c81-9a47-286c6e079a28",
      sensortype: "dbb5cb20-f46a-4c52-9f2f-a754d5b7b6ed",
      sensorSerial: "5af1da71-166a-4be7-a6a5-edd3077ccd8a",
      sensorConfiguration: "9eea4959-ee8d-45cc-8af1-46f62c71f64d",
      sensorStatistics: "8fc9d974-a253-444d-a2f5-f8892e60f721",
      measurementData: "b74506cb-9b0b-4245-a1bc-7ee3dfb046ae",
      measurementControl: "ddae3746-63ef-4f97-a4a0-ebe241bbab31",
      exposureTime: "b524c744-afcf-4a85-ae76-a9f19915695a",
      gain: "07df4055-a985-4bfd-a470-8426cf5d19b4",
      ledCurrent: "da40175c-e1dc-46a3-bc79-0ae2b2f8f40a",
      additionalInfo: "5b652fcf-1c68-4065-889a-0a66b61f1eb2",
      parameters: "39f19d05-2540-4634-81f3-6356aeb26c7f",
    },
  },
  otaProgrammerService: {
    uuid: "f959feb2-a75d-4927-9429-18432eebc795",
    characteristics: {
      error: "cb9de500-f0d3-445c-a0b6-7e0dad79a99b",
      firmwareInfo: "0ea9d32a-82d1-44e1-965f-24e1cada1b93",
      firmwareUpload: "90747c62-d67f-438a-8180-cbb840e1e362",
    },
  },
  commandService: {
    uuid: "10489186-154d-4736-a583-8ff90ce9953d",
    characteristics: {
      commandSend: "5b7a6f83-69ec-4648-bad9-b504fbdd22d1",
      responseReceive: "d0fa2f49-06fd-4095-9e32-a25a896ae668",
    },
  },
};
BLE_UUIDS.allServices = [
  BLE_UUIDS.deviceService.uuid,
  BLE_UUIDS.powerService.uuid,
  BLE_UUIDS.sensorheadService.uuid,
  BLE_UUIDS.otaProgrammerService.uuid,
  BLE_UUIDS.commandService.uuid,
];

const BLE_REQUEST_OPTIONS = {
  //services: [BLE_UUIDS.deviceService.uuid],
  namePrefix: "Comp",
  optionalServices: BLE_UUIDS.allServices,
};

const metaStore = useMetaStore();
const isConnected = ref(false);
const connectionInProgress = ref(false);

const isFwAbove = (versionToCheckAgainst) => {
  if (!deviceInfo.value.firmwareVersion) {
    console.warn(
      "Checking firmwareversion against " +
        versionToCheckAgainst +
        " but no firmware version available (yet)."
    );
    return false;
  }
  const [isMajor, isMinor] = deviceInfo.value.firmwareVersion.split(".");
  const [shouldMajor, shouldMinor] = versionToCheckAgainst.split(".");

  if (parseInt(isMajor) > parseInt(shouldMajor)) {
    return true;
  }
  if (
    parseInt(isMajor) === parseInt(shouldMajor) &&
    parseInt(isMinor) >= parseInt(shouldMinor)
  ) {
    return true;
  }
  return false;
};

// Calibration info Structure
const calibrationInfo = ref({
  trueValuePercentage: 99,
});

const deviceInfo = ref({
  name: "",
  firmwareVersion: "0",
  hardwareVersion: "0",
  type: "unknown",
  serial: null,
  sensorHead: {
    serial: null,
    type: 0,
    name: "",
    sensor: 0,
    driver: 0,
    statistiscs: {
      boot: 0,
      measurements: 0,
      writes: 0,
      length: 0,
    },
    additionalInfo: {},
  },
  parameters: {
    gain: 0,
    exposureTime: 0,
    ledCurrent: 0,
  },
});

const powerStatus = ref({
  batteryLevel: 0,
  batteryVoltage: 0,
  usbVoltage: 0,
});

const batteryLevel = ref(undefined);
const bleDeviceId = ref(null);
let bleDevice = undefined;
const ledCurrent = ref(0);
const gain = ref(0);
const exposureTime = ref(0);

function isArrayParameter(value) {
  if (value === undefined) return false;
  if (value === null) return false;
  return Array.isArray(value);
}

const sensorParameterOptions = ref(undefined);

const autoExposureParameters = ref({
  totalMeasurementTime: 5000, // ms
  minGain: 1, // abs value
  scoringMode: 0, // 0: SUM, 1: MAX
  overExposureThreshold: 90, // % of max value
});

// Flag if the device is in calibration mode
const calibrationMode = ref(false);
// Flag if the device is in dark current mode
const darkcurrentMode = ref(false);
// Flag to indicate running white balance calibration
const doWhiteBalanceNext = ref(false);
// List of calibration data
const calibrationList = ref([]);
// Current LED wise dark current
const lastPerLedDarkCurrent = ref(null);
// Display calibration data
const displayCalibration = ref(null);
// Flag that measurement is in progress
const measurementInProgress = ref(false);
// Flag that alert is in progress
const alertForceCalibration = ref(false);
// Flag that alerts no connected sensor
const alertSensorConnection = ref(false);

// Indicates if the expert mode is enabled
// The expert mode shows additional controls.
const isExpertMode = ref(false);

const rawCommandString = ref("");

const protectedAdditionalInfoKeys = ["led_wl", "sensor_wl", "manufact_date"];
const additionalInfoEditableItems = ref([]);

const controlMessages = {
  "scancorder.startMeasurement": startMeasurement,
  "scancorder.startCalibration": startCalibration,
};

const SENSOR_NAMES = {
  0x00: "None",
  0x01: "AS7341",
  0x02: "TSL2591",
  0xff: "Unknown",
};
function getSensorName(sensor) {
  return SENSOR_NAMES[sensor] || "Unknown";
}

const DRIVER_NAMES = {
  0x00: "None",
  0x01: "TLC59711",
  0xff: "Unknown",
};
function getDriverName(driver) {
  return DRIVER_NAMES[driver] || "Unknown";
}

const TYPE_NAMES = {
  0x00: "Not Connected",
  0x01: "Breadbord",
  0x02: "Universal 12",
  0x03: "Toothbrush",
  0x04: "Liquid Scanner",
  0x05: "Endoscope",
  0x06: "Leaf Clip",
  0x07: "Custom",
  0xfe: "Mockup",
  0xff: "Unknown",
};
function getTypeName(type) {
  return TYPE_NAMES[type] || "Unknown";
}

function onGainSelectChange(event) {
  gain.value = event.target.value;
}

let resetStateTimeout = null;

// Define the props for the component which are default values
const props = defineProps({
  // Default Wallet is Compolytics Demo Safe Global @ Gnosis Chain
  forceCalibration: {
    type: String,
    default: "false",
    choice: ["false", "true"],
  },
  expertMode: {
    type: String,
    default: "false",
    choice: ["false", "true"],
  },
  setAdditionalInfoOnConnect: {
    type: String,
    default: "{}",
    description:
      "JSON object (string representation) of measurement settings that should be set when the device is connected.",
  },
});

onMounted(() => {
  events.on("control", onControl);
  calibrationList.value = [];
  isExpertMode.value = props.expertMode === "true";
  console.log("Expert Mode", props.expertMode, isExpertMode.value);
});

onBeforeUnmount(() => {
  events.off("control", onControl);
});

function onControl(message) {
  console.log("onControl", message);
  for (const key in controlMessages) {
    if (key === message) {
      controlMessages[key]();
    }
  }
}

function checkTrueValueInput(event) {
  const value = parseFloat(event.target.value);
  if (value <= 0) {
    calibrationInfo.value.trueValuePercentage = 1;
  } else if (value > 100) {
    calibrationInfo.value.trueValuePercentage = 100;
    // In case we have a NaN value, we set it to 99
  } else if (isNaN(value)) {
    calibrationInfo.value.trueValuePercentage = 99;
  } else {
    calibrationInfo.value.trueValuePercentage = value;
  }
}

function getEstimatedMeasurementDuration() {
  const MINIMUM_MEASUREMENT_TIME = 10000;
  const measureMentTime =
    2000 +
    exposureTime.value *
      2 *
      (deviceInfo.value?.additionalInfo?.led_wl?.length || 12);
  return Math.max(MINIMUM_MEASUREMENT_TIME, measureMentTime);
}

async function onMeasurementDataFromDevice(sensorData) {

  console.log("onMeasurementDataFromDevice");
  let darkCurrent = undefined;

  // Get expected number of led measurements
  const expectedNumberOfValues =
    deviceInfo.value.sensorHead.additionalInfo.led_wl.length;

  // Get actual number, which is number of rows of the sensor matrix
  const actualNumberOfValues = sensorData.length;
   
  // If the number of values is one more than expected, we assume 
  // a dark current is included in the first value.
  if (actualNumberOfValues === expectedNumberOfValues + 1) {
    // If a dark current is included, store it separately.
    darkCurrent = sensorData[0];
    // and remove it from the actual data
    sensorData = sensorData.slice(1);
  }
  
  if (calibrationMode.value) {
    
    // We are in calibration mode, do not send data, Make calibration entry
    const calibInfo = {
      trueValuePercentage:
        parseFloat(calibrationInfo.value.trueValuePercentage) ||
        calibrationInfo.value.trueValuePercentage,
      trueValueFactor: calibrationInfo.value.trueValuePercentage / 100.0,
      darkCurrent: darkCurrent,
      sensorValue: sensorData,
      perLEDDarkCurrent: lastPerLedDarkCurrent.value
    };
    // Add data and true value to list
    calibrationList.value.push({ ...calibInfo });
    // Make calibration known
    displayCalibration.value = calibrationList.value;
    // Signal Calibration Mode is over
    calibrationMode.value = false;
    // Reset Measurement In Progress
    measurementInProgress.value = false;
    if (resetStateTimeout) {
      clearTimeout(resetStateTimeout);
      resetStateTimeout = null;
    }
  }
  else if (darkcurrentMode.value) {

    // We are in dark current mode, do not send data, save dark current
    // Save sensor data as dark current
    lastPerLedDarkCurrent.value = sensorData;
    // Signal Dark Current Mode is over
    darkcurrentMode.value = false;
    // Reset Measurement In Progress
    measurementInProgress.value = false;
    if (resetStateTimeout) {
      clearTimeout(resetStateTimeout);
      resetStateTimeout = null;
    }
    console.log("Do White Balance Next", doWhiteBalanceNext.value);
    if ( doWhiteBalanceNext.value ) {
      // Signal Doing Calibration Next
      calibrationMode.value = true;
      // Start White Balance Calibration
      startMeasurement();
      // Signal White Balance Calibration
      doWhiteBalanceNext.value = false;
    }

  } else {
    
    // In case we force calibration, we need to alert the user if no calibration is present
    // when measurement is started
    if (
      !calibrationMode.value &&
      props.forceCalibration == "true" &&
      calibrationList.value.length == 0
    ) {
      alertForceCalibration.value = true;
      return;
    }

    const data = dataMessage();
    data.source = "ScanCorderModule";

    const expectedNumberOfSensors =
      deviceInfo.value.sensorHead.additionalInfo.sensor_wl.length;
    
    const actualNumberOfSensors = sensorData[0].length;

    data.shape = [sensorData.length, sensorData[0].length];
    data.type = "raw";
    data.config = deviceInfo.value;
    data.config.power = powerStatus.value;
    data.values = sensorData;
    data.calibration = calibrationList.value;
    data.darkCurrent = darkCurrent;
    data.perLEDDarkCurrent = lastPerLedDarkCurrent.value

    data.meta = {
      xLabel: deviceInfo.value.sensorHead.additionalInfo.sensor_wl,
      yLabel: deviceInfo.value.sensorHead.additionalInfo.led_wl,
    };

    // Compute display colors
    // Only if applicable
    if (deviceInfo?.value?.sensorHead?.additionalInfo?.led_wl) {
      const xColor = [];
      deviceInfo.value.sensorHead.additionalInfo.led_wl.forEach((wl) => {
        xColor.push(colorFromWavelength(wl));
      });
      data.meta.xColor = xColor;
    }
    events.emit("data", data);
    console.log("Data Emitted", data);
    measurementInProgress.value = false;
    if (resetStateTimeout) {
      clearTimeout(resetStateTimeout);
      resetStateTimeout = null;
    }
  }  
}

async function connectToDevice() {
  console.log("connectToDevice");
  connectionInProgress.value = true;
  try {
    // We clear calibration first
    clearCalibration();

    bleDeviceId.value = undefined;

    const deviceId = await requestDevice(BLE_REQUEST_OPTIONS);
    if (!deviceId) {
      throw new Error("No device found");
    }
    bleDeviceId.value = deviceId.deviceId;
    bleDevice = BleDevice(bleDeviceId.value);
    if (!(await bleDevice.connect())) {
      throw new Error("Could not connect to device");
    }
    console.log(await bleDevice.getServices());
    await readDeviceInfo();
    await subscribeToPowerInformation();
    await subscribeToMeasurementData();
    if (isFwAbove("2.0")) {
      console.log("shouldn't be here");
      await subscribeToCommandResponse();
    }

    if (isFwAbove("2.0")) {
      sensorParameterOptions.value = await bleDevice.readJson(
        BLE_UUIDS.sensorheadService.uuid,
        BLE_UUIDS.sensorheadService.characteristics.parameters,
        '{',
      );
      console.log("sensorParameterOptions", sensorParameterOptions.value);
    }
    if (props.setAdditionalInfoOnConnect) {
      try {
        console.log(
          "Setting additional info",
          props.setAdditionalInfoOnConnect
        );
        const additionalInfo = JSON.parse(props.setAdditionalInfoOnConnect);
        console.log("Setting additional info", additionalInfo);
        await setAdditionalInfoToDevice(additionalInfo);
      } catch (error) {
        console.error("Error setting additional info", error);
      }
    }

    saveMeta();
    // Indicate conncection is successful
    isConnected.value = true;
    // Reset flags so we start proper
    commandLineBusy.value = false;
    calibrationMode.value = false;
    darkcurrentMode.value = false;
    doWhiteBalanceNext.value = false;
    calibrationList.value = [];
    lastPerLedDarkCurrent.value = null;
    displayCalibration.value = null;
    measurementInProgress.value = false;
    alertForceCalibration.value = false;

  } catch (error) {
    console.log("Error connecting to device", error);
  }
  connectionInProgress.value = false;
}

function saveMeta() {
  deviceInfo.value.parameters = {
    gain: gain.value,
    exposureTime: exposureTime.value,
    ledCurrent: ledCurrent.value,
  };
  metaStore.set("scancorder", deviceInfo.value);
}

async function readDeviceInfo() {
  console.log("readDeviceInfo");
  deviceInfo.value.name = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.deviceName
  );
  deviceInfo.value.firmwareVersion = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.firmwareVersion
  );
  deviceInfo.value.hardwareVersion = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.hardwareVersion
  );
  console.log("deviceInfo", deviceInfo.value);
  await readSensorHeadInformation();

  batteryLevel.value = await bleDevice.readDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8"
  );
  console.log("batteryLevel", batteryLevel.value);

  powerStatus.value.batteryLevel = batteryLevel.value;

  gain.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.gain,
    "uint8"
  );
  console.log("gain", gain.value);
  exposureTime.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.exposureTime,
    "uint16"
  );
  console.log("exposureTime", exposureTime.value);
  ledCurrent.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.ledCurrent,
    "uint8"
  );
}

async function readSensorHeadInformation() {
  deviceInfo.value.sensorHead = undefined;

  const isSensorConnected = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.connected,
    "uint8"
  );
  if (!isSensorConnected) {
    console.log("No sensor connected, Mind your own business.");
    return;
  }

  deviceInfo.value.sensorHead = {};
  deviceInfo.value.sensorHead.serial = await bleDevice.readString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensorSerial
  );
  console.log("sensorSerial", deviceInfo.value.sensorHead.serial);

  deviceInfo.value.sensorHead.type = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensortype,
    "uint8"
  );

  const sensorConfig = await bleDevice.readString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensorConfiguration
  );
  console.log(sensorConfig);
  try {
    const config = JSON.parse(sensorConfig);
    if (isFwAbove("2.0")) {
      deviceInfo.value.sensorHead.name = config.friendly_name;
      deviceInfo.value.sensorHead.sensor = getSensorName(config.sensor);
      deviceInfo.value.sensorHead.driver = getDriverName(config.driver);
      deviceInfo.value.sensorHead.type = getTypeName(config.type);
    } else {
      deviceInfo.value.sensorHead.name = config.name;
      deviceInfo.value.sensorHead.sensor = config.sensor;
      deviceInfo.value.sensorHead.driver = config.driver;
      deviceInfo.value.sensorHead.type = config.type;
    }
  } catch (error) {
    console.error("Error parsing sensor config", error, sensorConfig);
  }

  deviceInfo.value.sensorHead.additionalInfo = {};
  await readAdditionalInfo();
}

function forceRerenderSensorheadInfo() {
  const temp = JSON.stringify(deviceInfo.value.sensorHead);
  deviceInfo.value.sensorHead = undefined;
  const temp2 = JSON.parse(temp);
  setTimeout(() => {
    deviceInfo.value.sensorHead = temp2;
  }, 100);
}

async function readAdditionalInfo() {
  try {
    const additionalInfo = await bleDevice.readJson(
      BLE_UUIDS.sensorheadService.uuid,
      BLE_UUIDS.sensorheadService.characteristics.additionalInfo,
      '{',
    );
    console.log({ additionalInfo });
    deviceInfo.value.sensorHead.additionalInfo = additionalInfo;
    additionalInfoEditableItems.value = [];
    for (const key in additionalInfo) {
      if (!protectedAdditionalInfoKeys.includes(key)) {
        additionalInfoEditableItems.value.push({
          key: key,
          value: JSON.stringify(additionalInfo[key]),
        });
      }
    }
    additionalInfoEditableItems.value.push({
      key: "",
      value: "",
    });
    console.log({ additionalInfoEditableItems });
    forceRerenderSensorheadInfo();
  } catch (error) {
    console.error("Error reading additional info", error);
  }
}

async function subscribeToPowerInformation() {
  console.log("subscribeToPowerInformation");
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8",
    (value) => {
      powerStatus.value.batteryLevel = value;
    }
  );
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryVoltage,
    "uint16",
    (value) => {
      powerStatus.value.batteryVoltage = value;
    }
  );
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.usbVoltage,
    "uint16",
    (value) => {
      powerStatus.value.usbVoltage = value;
    }
  );

  // Manually read battery level once
  powerStatus.value.batteryLevel = await bleDevice.readDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8"
  );
}

async function subscribeToMeasurementData() {
  console.log("subscribeToMeasurementData");
  await bleDevice.subscribeJson(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.measurementData,
    (value) => {
      console.log("measurementData in callback", value);
      onMeasurementDataFromDevice(value);
    }
  );
}

async function subscribeToCommandResponse() {
  console.log("subscribeToCommandResponse");
  await bleDevice.subscribeDataType(
    BLE_UUIDS.commandService.uuid,
    BLE_UUIDS.commandService.characteristics.responseReceive,
    "string",
    (value) => {
      onCommandResponse(value);
    }
  );
}

function startMeasurement() {
  
  console.log("startMeasurement");
  // First check if sensor is connected at all
  if (!isConnected.value) {
    console.log("IsConnected: ", isConnected.value);
    alertSensorConnection.value = true;
    return;
  }

  // Second check is if we force calibration and no calibration data is present
  if (
    !(calibrationMode.value || darkcurrentMode.value) &&
    props.forceCalibration == "true" &&
    calibrationList.value.length == 0
  ) {
    alertForceCalibration.value = true;
    return;
  }

  // Thired check is if we have a measurement in progress
  if (measurementInProgress.value) {
    // Measurement still in progress, ignore
    return;
  }

  // Indicate we are starting a measurement
  measurementInProgress.value = true;
  // Decide what kind of measurement we are doing
  if (calibrationMode.value && isFwAbove("2.0")) {
    recordWhiteBalance();
  } else if (darkcurrentMode.value && isFwAbove("2.0")) {
    recordDarkCurrent();
  } else {    
    console.log("Measure sample");
    if (isFwAbove("2.0")) {  
      bleDevice.writeString(
        BLE_UUIDS.sensorheadService.uuid,
        BLE_UUIDS.sensorheadService.characteristics.measurementControl,
        "start"
      );  
    } else {
      bleDevice.writeString(
        BLE_UUIDS.sensorheadService.uuid,
        BLE_UUIDS.sensorheadService.characteristics.measurementControl,
        "start"
      );
    }
  }

  resetStateTimeout = setTimeout(() => {
    measurementInProgress.value = false;
    resetStateTimeout = null;
  }, getEstimatedMeasurementDuration());
}

async function startAutoExposure() {
  // Write status to console
  console.log("startAutoExposure");
  const autoExposureCommandString = `auto ${autoExposureParameters.value.totalMeasurementTime} ${autoExposureParameters.value.minGain} ${autoExposureParameters.value.scoringMode} ${autoExposureParameters.value.overExposureThreshold}`;
  const result = await sendCommandString(autoExposureCommandString);
  console.log("Auto Exposure Result: ", result);
}

function startCalibration() {
  // Write status to console
  console.log("startCalibration");
  // Reset display
  displayCalibration.value = null;

  if (isFwAbove("2.0")) {
    // Signal we are starting dark current
    darkcurrentMode.value = true;
    // Indicate to run white balance afterwards
    doWhiteBalanceNext.value = true;
  } else {
    // Signal we are in calibration mode, we do not do separate dark current in FW < 2.0
    calibrationMode.value = true;
  }
  // Start normal measurement
  startMeasurement();
}

function startDarkCurrent() {
  // Write status to console
  console.log("startDarkCurrent");
  // Signal we are starting dark current
  darkcurrentMode.value = true;
  // Start normal measurement
  startMeasurement();
}

function clearCalibration() {
  
  console.log("clearCalibration");
  if (measurementInProgress.value) {
    // Measurement still in progress, ignore
    return;
  }
  // Singla we are not in dark current mode
  darkcurrentMode.value = false;  
  // Signal we are not calibration mode
  calibrationMode.value = false;
  // Reset list of calibration data
  calibrationList.value = [];
  // Reset per led dark current
  lastPerLedDarkCurrent.value = null;
  // Reset list for trigger displays
  displayCalibration.value = null;
  // Reset next is white balance indicator
  doWhiteBalanceNext.value = false;
}

function waitForCommandsToFinish() {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!commandLineBusy.value) {
        clearInterval(interval);
        resolve();
      }
    }, 100); // Check every 100ms
  });
}

async function recordDarkCurrent() {

  console.log("recordDarkCurrent");
  await waitForCommandsToFinish();
  sendCommandString("dc");
}

async function recordWhiteBalance() {
  
  console.log("recordWhiteBalance");
  await waitForCommandsToFinish();
  sendCommandString("wb");
}

function setExposureTime() {
  console.log("setExposureTime", exposureTime.value);
  // We clear calibration first
  clearCalibration();
  if (isFwAbove("2.0")) {
    setAdditionalInfoToDevice({
      integration_time: Number(exposureTime.value),
      integration_times: null,
    });
  } else {
    // Write new exposure time
    bleDevice.writeDataType(
      BLE_UUIDS.sensorheadService.uuid,
      BLE_UUIDS.sensorheadService.characteristics.exposureTime,
      "uint32",
      exposureTime.value
    );
  }
  saveMeta();
}

function setGain() {
  console.log("setGain", gain.value);
  // We clear calibration first
  clearCalibration();
  // Write new gain
  if (isFwAbove("2.0")) {
    setAdditionalInfoToDevice({ gain: Number(gain.value), gains: null });
  } else {
    bleDevice.writeDataType(
      BLE_UUIDS.sensorheadService.uuid,
      BLE_UUIDS.sensorheadService.characteristics.gain,
      "uint8",
      gain.value
    );
  }
  saveMeta();
}

function setLedCurrent() {
  const current = ledCurrent.value;
  console.log("setLedCurrent", current);
  // We clear calibration first
  clearCalibration();
  // Write new led current
  bleDevice.writeDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.ledCurrent,
    "uint8",
    current
  );
  saveMeta();
}

function promptUserForDisconnect() {
  const choice = confirm("Do you want to disconnect from the device?");
  if (choice) {
    disconnectFromDevice();
  }
}

function disconnectFromDevice() {
  console.log("disconnectFromDevice");
  // We clear calibration first
  clearCalibration();
  // We disconnect
  isConnected.value = false;
}

function resetAlertCalibration() {
  alertForceCalibration.value = false;
}

function resetAlertSensorConnection() {
  alertSensorConnection.value = false;
}

function setAdditionalInfo(key, value) {
  console.log("setAdditionalInfo", key, value);
  const infoObject = {};
  try {
    infoObject[key] = JSON.parse(value);
    setAdditionalInfoToDevice(infoObject);
  } catch (error) {
    console.error("Error parsing additional info", error);
  }
}

async function setAdditionalInfoToDevice(infoObject) {
  if (!isFwAbove("2.0")) {
    console.warn("Firmware is not above 2.0, additional info not supported");
    return;
  }

  const infoString = JSON.stringify(infoObject);
  const commandString = "set " + infoString;
  await sendCommandString(commandString);
  await readAdditionalInfo();
}
const commandLineBusy = ref(false);

let responseBuffer = "";
function onCommandResponse(response) {
  
  console.log("Command response", response);
  responseBuffer += response;

  if (responseBuffer.endsWith(";;;")) {

    console.log("Full response", responseBuffer);

    if (responseBuffer === "auto exposure done;;;") {
      console.log("Auto exposure done");
      // Hack to force eeprom update
      commandLineBusy.value = false;
      responseBuffer = "";
      setAdditionalInfoToDevice({
        auto_exposure_done: new Date().toISOString(),
      });
      return;
    }
    
    if (responseBuffer === "set done;;;") {
      readAdditionalInfo();
      responseBuffer = "";
      commandLineBusy.value = false;
      return;
    }

    commandLineBusy.value = false;
    responseBuffer = "";
  }
}

async function sendCommandString(commandString) {
  
  if (commandLineBusy.value) {
    console.warn("Command line is busy, ignoring command");
    return;
  }
  const COMMAND_DELIMITER = ";;;";
  commandLineBusy.value = true;

  let fullCommandString = commandString + COMMAND_DELIMITER;

  let remainingBytes = fullCommandString.length;
  const maxChunkSize = 128;

  while( remainingBytes > 0 ) {
    const chunk = fullCommandString.slice(0, maxChunkSize);
    fullCommandString = fullCommandString.slice(maxChunkSize);
    remainingBytes = fullCommandString.length;
    await bleDevice.writeString(
      BLE_UUIDS.commandService.uuid,
      BLE_UUIDS.commandService.characteristics.commandSend,
      chunk
    );
  }
  
  console.log({ commandString });
  return;
}


</script>

<style></style>
