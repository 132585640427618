<template>
  <ion-card class="remote-serving">
    <ion-card-header>
      <ion-card-title>
        {{ props.modelTitle }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid v-if="servingInProgress">
        <ion-row>
          <ion-col>
            <ion-spinner name="circles"/>
          </ion-col>
        </ion-row>
        <ion-loading :is-open="servingInProgress" :message=SERVING_MODULE_STRINGS.APPLYMODEL_POPUP spinner="circles" />
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length === 0 && !servingInProgress">
        <p>{{ SERVING_MODULE_STRINGS.NEED_DATA }}</p>
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length !== 0 && !servingInProgress">
        <p>Model Prediction:</p>
        <ion-row class="prediction-row">
          <ion-col size="auto" v-for="(prediction, index) in predictionInfoList" :key="index" class="prediction-col" :style="{ transform: `scale(${props.predictionDisplayScaling/100})`, transformOrigin: 'center center', margin: `${6 * 1 * props.predictionDisplayScaling/100}px` }">
            <div class="prediction-cell">
              <div style="color: white">{{ prediction.name.replace(/_/g, ' ') }}</div>
              <div v-if="prediction.unit !== undefined">{{ prediction.unit }}</div>
              <div :style="{ color: prediction.color }" class="prediction-result">{{ prediction.score }}</div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
/*
.prediction-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  // Adds a border to each cell
  min-height: 5px;
  // Adjust height as necessary
  text-align: center;
  // Centers text within items
}
*/
.prediction-row {
  display: flex;
  justify-content: center; /* Center the cells horizontally */
  flex-wrap: wrap; /* Allow cells to wrap on smaller screens */
}

.prediction-col {
  display: flex;
  justify-content: center; /* Center the content within each column */
  align-items: center;
  padding: 10px; /* Add some padding for spacing */
  margin: 10px; /* Add some margin between the columns */
}

.prediction-cell {
  text-align: center; /* Center the text in the cells */
  padding: 10px; /* Add padding inside the cells */
  background-color: #000000; /* Optional: Add a background color */
  border-radius: 5px; /* Optional: Add some rounding to the cells */
  box-sizing: border-box;
}
</style>

<script setup>
import Web3 from "web3";
import { IonLoading, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonSpinner, IonCardTitle, IonCardContent } from "@ionic/vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { events } from "@/utils/events";
import { SERVING_MODULE_STRINGS} from '@/const/strings';
import { v4 as uuid4 } from "uuid";

// List of Predictions
const predictionInfoList = ref([]);
// Serving in progress indicator flag
const servingInProgress = ref(false);

// Define the props for the component which are default values
const props = defineProps({
  // Default Wallet is Compolytics Demo Safe Global @ Gnosis Chain
  modelWallet: {
    type: String,
    default: "Please provide wallet address."
  },
  // Default Model NFT Token ID
  modelTokenID: {
    type: Number,
    default: 1
  },
  // Customer secret key to sign the message
  customerKey: {
    type: String,
    default: ""
  },
  // Default AWS Lambda is Frankfurt location
  neuralEngineURL: {
    type: String,
    default: "https://s56nobccb2.execute-api.eu-central-1.amazonaws.com/Prod/score",
    choice: ["https://s56nobccb2.execute-api.eu-central-1.amazonaws.com/Prod/score", "https://i5gtz58vpi.execute-api.ap-southeast-1.amazonaws.com/Prod/score", "https://luazhc6n21.execute-api.ap-southeast-2.amazonaws.com/Prod/score","https://zkggvca370.execute-api.us-east-1.amazonaws.com/Prod/score","https://mz7pajtoc7.execute-api.eu-west-1.amazonaws.com/Prod/score"]
  },

  // What message to listen for
  modelTitle: {
    type: String,
    default: "Model Serving",
  },

  // What message to listen for
  dataMessage: {
    type: String,
    default: "data",
  },

  // What to display from the response of the model
  predictionFieldName: {
    type: String,
    default: "",
  },

  // What to display from the response of the model
  predictionDisplayName: {
    type: String,
    default: "",
  },

  // What units to show for the prediction
  predictionDisplayUnits: {
    type: String,
    default: "",
  },

  predictionMetaName: {
    type: String,
    default: "",
  },

  taskFieldName: {
    type: String,
    default: "",
  },

  // We use Safe Global like prefix for the blockchain address
  blockchainPrefix: {
    type: String,
    default: "gno:",
  },

  // Simple scaling factor for the display in percent
  predictionDisplayScaling: {
    type: Number,
    default() {
      return 100;
    }
  },

  protectedProperty: {
    type: String,
    default: ""
  }
});

onMounted(() => {
  // Setup event listener for data messages 
  events.on(props.dataMessage, onData);
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

function processPredictions(props, scoreReponse) {

  const predictionList = []; // Assuming this is the initial setup

  let renameTable = {};
  try {
    renameTable = JSON.parse(props.predictionDisplayName);
  } catch (error) {
    renameTable = {};
  }

  let unitTable = {};
  try {
    unitTable = JSON.parse(props.predictionDisplayUnits);
  } catch (error) {
    unitTable = {};
  }

  let metaTable = {};
  try {
    metaTable = JSON.parse(props.predictionMetaName);
  } catch (error) {
    metaTable = {};
  }

  // Ensure predictionFieldName is available and a string
  if (!props || typeof props.predictionFieldName !== 'string') {
    return []; // or handle the error appropriately
  }

  // Assume we have a regression task
  let taskType = "regression";
  if (!props || typeof props.taskFieldName === 'string') {
    // if taskfield is set, we take it
    if (scoreReponse && scoreReponse['prediction'] && scoreReponse['prediction'][props.taskFieldName])
      taskType = scoreReponse['prediction'][props.taskFieldName];
  }
 
  // Split field names and trim whitespace
  const predFieldList = props.predictionFieldName.split(',').map(name => name.trim());
  
  // Process each field name and create predictions
  predFieldList.forEach(fieldName => {

    // Check if the field name is in the scoreReponse
    if (scoreReponse && scoreReponse['prediction'] && scoreReponse['prediction'][fieldName]) {
      // Extract the numeric prediction from the scoring payload
      const number = parseFloat(scoreReponse['prediction'][fieldName]);
      // If the field name is in the rename table, we use the display name
      const displayFieldName = renameTable[fieldName] ? renameTable[fieldName] : fieldName;
      // If the field name is in the unit table, we use the unit name and generate a proper formating
      const displayUnitName = unitTable[fieldName] ? "[" + unitTable[fieldName] + "]" : undefined;
      // Create the prediction structure with all display information
      const prediction = {
        name: displayFieldName,
        score: number.toFixed(2),
        unit: displayUnitName,
        color: "#ffffff" // Default color
      };

      // If we have classification task, we replace the score with the class name and get the color
      // asscoiated with the class
      if (taskType == "classification") {
        if (metaTable[fieldName] && scoreReponse['prediction'][metaTable[fieldName]] && scoreReponse['prediction'][metaTable[fieldName]]['label']) {
          prediction.score = scoreReponse['prediction'][metaTable[fieldName]]['label'][0]
          prediction.color = scoreReponse['prediction'][metaTable[fieldName]]['color'][0]
          // If the color string is to short, fill with 0
          if (prediction.color.length < 7) {
            prediction.color = prediction.color + "0".repeat(7 - prediction.color.length);
          }
        }
      }  

      // Add the prediction to the list
      predictionList.push(prediction);
    }
  });
  return predictionList;
}


function parseResponseForImage(response) {
    // Initialize a new dictionary
    const result = {};
    // Iterate over the response fields
    for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
            const value = response[key];

            // Check if the value is a Data URI for an image
            if (typeof value === 'string' && value.startsWith('data:image/')) {
                // Add the Data URI to the "values" field in the new dictionary
                result.values = value;
                result.type = "image";
                result.source = "ServingModule";
                result.shape = [];
                result.config = {};
                result.meta = {};
                result.uuid = uuid4();
                result.timestamp = Date.now();
                break; // Stop after finding the first Data URI
            }
        }
    }

    return result;
}

async function onData(data) {

  // Set serving flag as working
  servingInProgress.value = true;
  // Clear the prediction list
  predictionInfoList.value = [];
  // Setup web3
  const web3 = new Web3();

  // Hack, we need to remove field calibration if it is any empty list
  // -----------------------------------------------------------------
  // This should be done in the model, but older version are deployed with not ignoring calibration when empty
  if (data.calibration && data.calibration.length == 0) {
    delete data.calibration;
  }

  // Lets turn this into a json request
  const inputData = JSON.stringify(data);
  // If calibration does not exist, add it back as empty
  if (!data.calibration) {
    data.calibration = [];
  }
  
  try
  {
    // We need to buld the proper request for the neural engine
    const eventPayload = {};
    eventPayload['model'] = {};
    eventPayload['model']['model_wallet'] = removePrefixSubstring(props.modelWallet, props.blockchainPrefix);
    eventPayload['model']['score_wallet'] = removePrefixSubstring(props.modelWallet, props.blockchainPrefix);
    // The token id is the unique model identifier, token id is set in secrets.json
    eventPayload['model']['token_id'] = props.modelTokenID;
    // Input payload is sensor reading json as string
    eventPayload['input'] = inputData;
    // We sign the message using the private key and recover the signature
    const secretKey = addPrefixSubstring(removePrefixSubstring(props.customerKey, props.blockchainPrefix), "0x");
    const signedMessage = web3.eth.accounts.sign(inputData, secretKey);
    // Store signature
    eventPayload['model']['signature'] = signedMessage.signature;
    console.log("Event Payload", JSON.stringify(eventPayload));
    // Lets send the request to the neural engine
    const response = await fetch(props.neuralEngineURL, {
      method: "POST",
      body: JSON.stringify(eventPayload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    console.log("Reponse: ", response);
    // Lets check the response status
    if (response.status == 200) {
      // We have a valid response
      const scoreReponse = await response.json();

      console.log("Response", scoreReponse);
      if (scoreReponse['prediction'] == 'ERROR') {
        // If the prediction is an error, we will display the error message
        const message = {
          name: "serving error",
          score: JSON.stringify(scoreReponse['info'])
        };
        predictionInfoList.value.push(message);
      } else {
        // if we a valid prediction list, lets process the model output
        predictionInfoList.value = processPredictions(props, scoreReponse);
        // We save prediction and model for later use
        data.prediction = {};
        data.prediction.tokenid = props.modelTokenID;
        data.prediction.values = scoreReponse['prediction'];
        // Sending prediction message
        events.emit("prediction", data);
        // Parse the response for an image
        const image_data = parseResponseForImage(scoreReponse['prediction']);
        // if we have non empty data, send it
        if (Object.keys(image_data).length !== 0) {
          events.emit("image", image_data);
        }  
      }
    } else {
      // We have an error with the request  
      console.log('Request was not successful.')
      const scoreReponse = await response.json();
      const message = {
        name: SERVING_MODULE_STRINGS.REQUEST_ERROR,
        score: JSON.stringify(scoreReponse)
      };
      predictionInfoList.value.push(message);
    }
  } catch (error) {
    // We catch any general error
    console.log("Error: ", error);
    const message = {
      name: SERVING_MODULE_STRINGS.CONNECTION_ERROR,
      score: "Cannot reach cloud server."
    };
    predictionInfoList.value.push(message);
  }
  servingInProgress.value = false;
}

// Helper function to remove prefix from the string
function removePrefixSubstring(inputString, prefix) {
  if (inputString.startsWith(prefix)) {
    return inputString.substring(prefix.length);
  }
  return inputString;
}

function addPrefixSubstring(inputString, prefix) {
  if (inputString.startsWith(prefix)) {
    return inputString;
  }
  return prefix + inputString;
}

</script>

<style></style>