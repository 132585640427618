<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ props.moduleTitle }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content v-if="tableData.length === 0">
      <ion-grid>
        <p>No predictions available. Waiting for data.</p>
      </ion-grid>
    </ion-card-content>
    <ion-card-content v-if="tableData.length !== 0">
      <!-- HTML table with Vue's v-for -->
      <!-- HTML table with Vue's v-for -->
      <table class="ion-table">
        <thead>
          <tr>
            <th>Sample ID</th>
            <th v-for="header in tableHeader" :key="header">{{ header.replace(/_/g, ' ') }}</th>          
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in tableData" :key="data.sampleId">
            <td>{{ data.sampleId }}</td>
            <td v-for="header in tableHeader" :key="header">{{ formatPrediction(data.values[header]) }}</td>     
          </tr>
        </tbody>
      </table>
    </ion-card-content>
    <ion-button fill="clear" @click="clearTable()">Clear Table</ion-button>
    <ion-button fill="clear" @click="downloadCSV()">Export CSV</ion-button>
  </ion-card>
</template>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #eee;
  /* Light grey line for each row */
}

th {
  background-color: #f4f4f4;
  /* Light grey background for headers */
}
</style>

<script setup>
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonSpinner, IonCardTitle, IonCardContent } from "@ionic/vue";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { events } from "@/utils/events";
import { useMetaStore } from "@/store/meta";

// Table Data 
const tableData = ref([]);
const tableHeader = ref([]);

// Define the props for the component which are default values
const props = defineProps({

  // Default module title
  moduleTitle: {
    type: String,
    default: "Prediction Tabel",
  },

  // What message to listen for
  dataMessage: {
    type: String,
    default: "data",
  },

  // What to display from the response of the model
  predictionFieldName: {
    type: String,
    default: "prediction",
  },

  // What units to show for the prediction
  predictionDisplayUnits: {
    type: String,
    default: "",
  },

  // What to display from the response of the model
  predictionDisplayName: {
    type: String,
    default: "",
  }

});

const metaStore = useMetaStore();

onMounted(() => {
  // Setup event listener for data messages 
  events.on(props.dataMessage, onData);
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

function formatPrediction(prediction) {
  return Number(parseFloat(prediction)).toFixed(2);
}

async function onData(data) {

  // Check if field is in the data
  if (!data[props.predictionFieldName]) {
    return;
  }

  // Get sample id
  let additionalData = "";
  // We can use the metaStore to add additional data to the filename.
  // This is useful to quickly identify files that belong to a certain
  // experiment, for example.
  const infoData = metaStore.get("info");
  if (infoData?.includeInFilename) {
    // It might seem more intuitive to have the iteration over the
    // keys of stored in includeInFilename, but this way we can
    // be sure that the order of the keys is more consistent.
    console.log( infoData );
    for (const key in infoData) {
      if (infoData.includeInFilename.includes(key))
        additionalData += `${infoData[key]}_`;
    }
  }
  // Remove last underscore
  additionalData = additionalData.slice(0, -1);

  console.log(data)
  // Get the prediction from the data
  const sample_uuid = data.uuid;
  const timestamp = data.timestamp;
  const prediction_values = data[props.predictionFieldName].values;

  let unitTable = {};
  try
  {
    unitTable = JSON.parse(props.predictionDisplayUnits);
  } catch (error) {
    unitTable = {};
  }

  // if we have a display name, we should use it
  let prediction_values_renamed = {};
  try
  {
    const renameTable = JSON.parse(props.predictionDisplayName);
    Object.keys(prediction_values).forEach(key => {
      // if whe have a non empty renameTable, we ignore the key, if is not listed
      if (Object.keys(renameTable).length > 0 && !Object.keys(renameTable).includes(key)) {
        return;
      }
      const displayFieldname = renameTable[key] ? renameTable[key] : key;
      const displayUnitName = unitTable[key] ? "[" + unitTable[key] + "]" : "";
      prediction_values_renamed[String(displayFieldname + " " + displayUnitName).trim()] = prediction_values[key];
    });
  } catch (error) {
    // if we have an error, we just use the original values
    prediction_values_renamed = prediction_values;
  }

  const prediction_tokenid = data[props.predictionFieldName].tokenid;
  const prediction_sampleid = additionalData;

  const prediction = {
    timestamp: timestamp,
    uuid: sample_uuid,
    tokenId: prediction_tokenid,
    sampleId: prediction_sampleid,
    values: prediction_values_renamed
  };

  // Update table data
  tableData.value.unshift(prediction);
  tableHeader.value = getHeaders.value;
}

async function convertToCSV(data) {

  const rows = [];
  // Collect headers
  const headers = ['Sample ID', 'Token ID', 'UUID', 'Timestamp'];
  data.forEach(item => {
    Object.keys(item.values).forEach(key => {
      if (!headers.includes(key)) {
        headers.push(key);
      }
    });
  });

  // Build header row
  rows.push(headers.join(';'));
  // Build data rows
  data.forEach(item => {
    const row = [item.sampleId, item.tokenId, item.uuid, item.timestamp];
    headers.slice(row.length).forEach(header => {
      row.push(item.values[header] || ''); // Add the prediction value or empty if not present
    });
    rows.push(row.join(';'));
  });

  return rows.join('\n');
}

async function downloadCSV() {

  const timestamp = await getCurrentTimestamp();
  const filename = `${timestamp}_prediction_table.csv`;
  // Convert table data to CSV, use ... to get plain array
  const csvData = await convertToCSV([...tableData.value]);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

async function clearTable() {
  // trigger table clearing
  tableData.value = [];
  tableHeader.value = getHeaders.value;
}

const getHeaders = computed(() => {
  const headers = new Set();
  const plainTable = [...tableData.value];
  plainTable.forEach(item => {
    Object.keys(item.values).forEach(key => {
      headers.add(key);
    });
  });
  return Array.from(headers);
});

async function getCurrentTimestamp() {
  const now = new Date();
  const formatted = now.toISOString().replace(/[-:]/g, '').replace('T', '_'); // Replace 'T' with '_'
  return formatted.slice(0, 15);  // Adjusts to generate 'YYYYMMDD_HHMMSS'
}

</script>

<style></style>